import axios from "axios";
import { useEffect, useState } from "react";
import { SECTIONS_COLORS_URL } from "../services/config";
import ContextServiceSectionsColors from "./ContextServiceSectionsColors";

const ServiceSectionsColors = ({ children }) => {
  const [coursesData, setCoursesData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(SECTIONS_COLORS_URL).then(
        (response) => response.data
      );
      setCoursesData(response);
    };

    axiosCourses();
  }, []);

  return (
    <ContextServiceSectionsColors.Provider value={coursesData}>
      {children}
    </ContextServiceSectionsColors.Provider>
  );
};

export default ServiceSectionsColors;
