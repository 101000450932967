import { Button, Container, Form } from "react-bootstrap";

import "./Login.scss";
import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../services/firebase";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIsLogin from "./isLogin";

function Login() {
  const [errorLogin, setErrorLogin] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [valid, setValid] = useState(true);

  useIsLogin();

  const login = async (e) => {
    e.preventDefault();
    await signInWithEmailAndPassword(auth, email, pass)
      .then((userCredential) => {
        console.log(userCredential);
        navigate("/admin");
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorLogin(errorMessage);
      });
  };

  useEffect(() => {
    if (email === "" && pass === "") {
      setValid(true);
    } else if (email === "" || pass === "") {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [email, pass]);

  return (
    <Container className="customContainer">
      <Form onSubmit={login}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Insira seu email"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) => setPass(e.target.value)}
            placeholder="Insira sua senha"
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={valid}>
          Entrar
        </Button>
      </Form>
      {errorLogin && <Typography>{errorLogin}</Typography>}
    </Container>
  );
}

export default Login;
