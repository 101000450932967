import "../style.scss";
import images from "../components/images";

function AlemDissoVaiAprender() {
  return (
    <div className="container-fluid background-blue">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <h1 className="mt-3">Além disso vai aprender</h1>
            <img
              src={images.imgArrow}
              alt="A quem se destina"
              className="img-fluid d-none d-sm-block mx-auto mt-12 img-quem-se-destina"
            />
          </div>
          <div className="col-sm-12 col-md-8">
            <ul className="list-ul">
              <li>
                <b>Que estilo pessoal não é 7 estilos universais</b> – Vai
                aprender a construir seu próprio estilo, podendo adaptá-lo
                sempre que precisar.
              </li>
              <li>
                <b>Seu corpo não é uma salada de frutas, nem uma forma única</b>
                - Descobrir suas proporções corporais através da análise 360°;
                obtendo uma visão clara do que é peso visual, cintura
                base/construída, proporção de tronco x pernas, consequentemente
                trará mais liberdade para se vestir do que se enquadrar em um
                único padrão.
              </li>
              <li>
                <b>Através dos 3A do estilo</b> – Como montar um look para
                qualquer ocasião e não se sentir mais insegura ou indecisa na
                hora de se vestir.
              </li>
              <li>
                <b>Seu tempo é precioso</b> – Com um armário que te representa,
                sabendo dominar as técnicas de combinação de cores, mix de
                estampas e multiplicação de looks você não precisará mais ficar
                horas e horas na frente do espelho sem saber o que colocar.
              </li>
              <li>
                <b>Sobre modelagens, linhas, cores e texturas</b> e como aplicar
                na sua imagem pessoal de forma estratégica, atrelado a proporção
                corporal do que você quer disfarçar e valorizar.
              </li>
              <li>
                <b>Identificar roupas de qualidade</b> independente do preço.
              </li>
              <li>
                <b>Sobre tecidos</b> e como saber o que é mais indicado para a
                sua necessidade, região que mora e estilo de vida.
              </li>
              <li>
                <b>Preço não é o único fator determinante</b> de uma boa compra
                - Realizar compras inteligentes é você analisar o real custo x
                benefício daquela peça pensando em todas as esferas da sua vida.
              </li>
              <li>
                <b>Trabalhar a sua imagem profissional</b> para que consiga
                trazer mais segurança e confiança no dia a dia e assim
                conquistar aquele cargo que tanto almeja.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlemDissoVaiAprender;
