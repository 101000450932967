import "./footer.scss";
import { Typography } from "@mui/material";
import Social from "../social/social";

function Footer() {
  return (
    <footer className="footer-16371 mt-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center">
            <div className="social mb-4">
              <Typography variant="h6">Redes Sociais</Typography>
              <Social />
            </div>
            <div className="copyright">
              <p className="mb-0">
                <small>&copy; LuLopes. All Rights Reserved.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
