import { makeStyles } from "@mui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: "-1",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      position: "relative",
      top: "54px",
    },
  },
  image: {
    width: "100%",
    height: "auto",
    position: "absolute",
    bottom: "0",
    top: "0",
    left: "0",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  containerCustom: {
    height: "100%",
    overflow: "hidden",
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
  },
  title: {
    position: "absolute",
    top: "50%",
    left: "0%",
    width: "80%",
    fontSize: "64px !important",
    color: theme.palette.text.primary,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
    "& span": {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "80px",
      left: "0",
    },
  },
});

export default useStyles;
