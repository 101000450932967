import "../style.scss";
import images from "../components/images";

function ZeroCriativa() {
  return (
    <div className="container-fluid">
      <div className="container zero-criativa">
        <div className="row gx-0 gy-0">
          <div className="col-24 col-md-6">
            <h6>
              <b>"GUARDA-ROUPA 3X"</b> vai ensinar a tirar seus looks do mood
              sem graça, mesmo se você...
            </h6>
          </div>
          <div className="col-24 col-md-6">
            <img
              src={images.imgWomanZeroCreative}
              alt="Se acha zero criativa?"
            />
          </div>
          <div className="col-24 col-md-6">
            <img src={images.imgWomanInsecure} alt="Se acha zero criativa?" />
          </div>
          <div className="col-24 col-md-6">
            <img
              src={images.imgWomanBasicClothes}
              alt="Se acha zero criativa?"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZeroCriativa;
