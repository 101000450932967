import "../style.scss";
import images from "../components/images";
import ButtonInscrever from "../components/button";

function PiramideEstiloPessoal() {
  return (
    <>
      <div className="container-fluid background-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>
                UM MÉTODO QUE FOI CRIADO PARA FACILITAR SUA JORNADA DO ESTILO
                "Escalada do estilo pessoal"
              </h1>
            </div>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <p>
                    A maioria dos cursos de Estilo pessoal foca em ensinar a
                    teoria de forma engessada:
                  </p>
                  <p>
                    Você identifica seu estilo baseado apenas nos 7 estilos
                    universais e você só pode ter até 2 estilos definidos,
                    ensina truques de estilo baseado nos 5 biotipos; triangulo,
                    triangulo invertido, oval, retangular e ampulheta e esquece
                    que seu corpo é visto em 360º e ele pode mudar ao longo da
                    vida...
                  </p>
                  <p>
                    O método GD3x, trabalha desde o entendimento de você como
                    individuo, pois, tudo se inicia a partir dai, se você não
                    entende o que se passa dentro de si, como vai querer que as
                    outras pessoas te enxerguem? Não adianta te passar um script
                    já pronto sobre os 7 estilos universais se você não entender
                    os fundamentos de design de uma peça.
                  </p>
                  <p>
                    No GD3x, focamos em estratégia e conhecimento que você pode
                    usar pra vida toda sempre que precisar.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstiloPessoal}
                    alt="Piramide do estilo pessoal"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <p>
              <b>Clique no botão abaixo</b> e mostre que ter estilo é dizer ao
              mundo que suas prioridades são seu valores pessoais. Você não
              precisa seguir as tendências para se sentir linda, confiante e
              estilosa.
            </p>
            <ButtonInscrever />
          </div>
        </div>
      </div>
      {/* MÉTODO PIR MIDE DO ESTILO*/}
      {/* <div className="container-fluid background-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstilo1}
                    alt="Autoconhecimento"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <h1>Autoconhecimento</h1>
                  <p className="mt-4">
                    Muitos cursos por aí, focam na pergunta
                    <b> “Como você quer que as pessoas te vejam”?</b> – Aqui
                    focamos em você em primeiro lugar, pois se você não tem
                    claro o que quer transmitir para VOCÊ MESMA, como quer que
                    as pessoas te enxerguem?
                  </p>
                  <p>
                    Meu corpo, minhas regras – chega de seguir regrinhas dos 5
                    biotipos, aqui você vai aprender a analisar seu corpo em
                    360° e entender como equilibrar sua silhueta em qualquer
                    estágio da sua vida.
                  </p>
                  <p>
                    O seu estilo é muito mais do que apenas os 7 estilos
                    universais. Entender a teoria faz parte do aprendizado, mas
                    a sociedade muda e precisamos estar antenados as mudanças,
                    basear seu estilo em apenas 3 modelos é muito limitador.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* MÉTODO PIR MIDE DO ESTILO - 2*/}
      {/* <div className="container-fluid background-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <h1>Ambientes externos</h1>
                  <p className="mt-4">
                    Os locais que você frequenta, faz total diferença na
                    definição do seu estilo.
                  </p>
                  <p>
                    Imagina a seguinte situação: Você gosta de ambientes
                    extremamente informais, mas tem um armário cheio de peças de
                    alfaiataria e que toda vez que vai sair, é aquela
                    insegurança e sensação de não ter roupa e de fato não tem,
                    porque seu armário foi formado sem pensar nesses ambientes.
                  </p>
                  <p>
                    Passamos muitas horas no ambiente de trabalho sem poder
                    falar o que pensamos e a sensação se intensifica quando você
                    precisa se fantasiar da cabeça aos pés para passar uma
                    imagem que muitas vezes nem condiz com você. Agora imagina
                    poder equilibrar sua essência coma imagem que “precisa”
                    passar no trabalho?
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstilo2}
                    alt="Ambientes externos"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* MÉTODO PIR MIDE DO ESTILO - 3*/}
      {/* <div className="container-fluid background-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstilo3}
                    alt="Códigos de design"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <h1>Códigos de design</h1>

                  <p className="mt-4">
                    A comunicação da nossa imagem é feita através de símbolos e
                    códigos, e esses símbolos são interpretados de forma
                    instintivamente por qualquer ser humano. Quando você aprende
                    a dominar esses elementos sem se prender aos 7 estilos
                    universais, você consegue usar esse recurso para tudo na sua
                    imagem, como roupas, calçados, acessórios e cabelo.
                  </p>
                  <p>
                    Aqui você terá o conhecimento que poderá aplicar em todas as
                    fases da sua vida e não somente para a fase que está vivendo
                    atualmente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* MÉTODO PIR MIDE DO ESTILO - 4*/}
      {/* <div className="container-fluid background-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <h1>Guarda-roupas</h1>
                  <p className="mt-4">
                    Nosso armário é um acervo de peças que vamos colecionando ao
                    longo das nossas vidas, porém o mesmo precisa acompanhar as
                    diferentes fases dela e quando esse alinhamento não
                    acontece, você se frusta e na ânsia de se sentir bem acaba
                    comprando novas roupas – porém sem entender qual é essa nova
                    fase você acaba colecionando peças sem sentido e não tem um
                    armário que te representa e esse ciclo nunca acabará, caso
                    não faça as pazes com ele.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstilo4}
                    alt="Guarda-roupa"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* MÉTODO PIR MIDE DO ESTILO - 5*/}
      {/* <div className="container-fluid background-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                  <img
                    src={images.imgMetodoPiramideEstilo5}
                    alt="Estilo Pessoal"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <h1>Estilo pessoal</h1>

                  <p className="mt-4">
                    Quando falamos de estilo pessoal, muitas mulheres acreditam
                    ser impossível encontrar o seu, quando na verdade, já
                    nascemos com ele, mas nunca paramos para observar, nunca
                    paramos para entender o que de fato gostamos e o que de fato
                    valoriza nosso corpo do jeito que é, pois, somos doutrinadas
                    a seguir um padrão de estética e a consumir moda de forma
                    desenfreada e sem consciência.
                  </p>
                  <p>
                    Somos seres mutáveis e assim como tudo na vida, nosso estilo
                    acaba refletindo aquele cenário ao qual estamos inseridas no
                    momento atual, então é super normal essa transição de
                    estilos que passamos desde a nossa adolescência até a idade
                    mais madura e em cada estágio das nossas vidas queremos
                    reforçar uma mensagem diferente.
                  </p>
                  <p>
                    Você tem estilo, só precisa de um direcionamento para
                    aprimora-lo respeitando sua essência.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <p>
                <b>Clique no botão abaixo</b> e mostre que ter estilo é dizer ao
                mundo que suas prioridades são seu valores pessoais. Você não
                precisa seguir as tendências para se sentir linda, confiante e
                estilosa.
              </p>
              <ButtonInscrever />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default PiramideEstiloPessoal;
