import { Button, TextField, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import useStyles from "./useStyles";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { CONTACT_URL } from "../../services/config";
import axios from "axios";
import { useEffect } from "react";
import ContextServiceSectionsColors from "../../global/ContextServiceSectionsColors";

const serviceId = "service_3polxa7";
const templateId = "template_4itstnd";
const userID = "Y3AFCQL74g-UU5tWc";

function Contact() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [messageTxt, setMessageTxt] = useState("");

  function cleanUpFields() {
    setName("");
    setEmail("");
    setPhone("");
    setMessageTxt("");
  }

  function maskNumber(phoneNumber) {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    const str = phoneNumber.replace(/[^0-9]/g, "").slice(0, 11);
    const result = str.replace(regex, "($1)$2-$3");
    setPhone(result);
  }

  const sendEmail = useCallback((e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, e.target, userID).then(
      () => {
        toast.success("Mensagem enviada com sucesso!");
        cleanUpFields();
      },
      (error) => {
        if (!error) return;
        toast.error(error);
      }
    );
  }, []);

  const [data, setData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(CONTACT_URL).then(
        (response) => response.data
      );
      setData(response.data);
    };
    axiosCourses();
  }, []);

  const colors = useContext(ContextServiceSectionsColors);

  return (
    <div
      className={`container-fluid ${classes.root}`}
      style={{
        backgroundColor: colors?.data ? colors.data.attributes.Contact : "#fff",
      }}
    >
      <div className="container">
        {data?.attributes?.title && (
          <Typography variant="h4" color="primary">
            {data.attributes.title}
          </Typography>
        )}
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            {data?.attributes?.image?.data?.attributes?.url && (
              <img
                src={
                  data?.attributes?.image?.data?.attributes?.formats?.large?.url
                }
                alt=""
                className={classes.image}
              />
            )}
          </div>
          <div className="col-12 col-md-6">
            <form
              className={`${classes.form}`}
              autoComplete="off"
              onSubmit={sendEmail}
            >
              <TextField
                id="Nome"
                label="Nome"
                fullWidth
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <TextField
                id="email"
                type="email"
                label="E-mail"
                fullWidth
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                id="telefone"
                label="Telefone"
                fullWidth
                name="telefone"
                value={phone}
                onChange={(e) => maskNumber(e.target.value)}
                required
              />
              <TextField
                multiline
                id="message"
                label="Mensagem"
                fullWidth
                name="message"
                value={messageTxt}
                onChange={(e) => setMessageTxt(e.target.value)}
                required
              />
              <Button variant="contained" color="primary" type="submit">
                Enviar
              </Button>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
