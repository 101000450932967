import "../style.scss";
import images from "../components/images";
import ButtonInscrever from "../components/button";

function SuporteOnline() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="zoom mx-auto">
              <img src={images.zoom} alt="" />
              <p className="mt-4 mb-4 text-center">
                Suporte ao vivo para tirar todas suas dúvidas como você nunca
                teve em um curso de estilo.
              </p>
              <ButtonInscrever />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <p>
              Poder estudar quando e onde quiser sem dúvida é libertador,
              prático e mais econômico. Porém, todo ser humano precisa de uma
              interação seja presencial ou ao vivo. Na minha visão ter contato
              com o professor é fundamental para o aprendizado. Tirar dúvidas
              apenas por mensagens escrita não funciona comigo.
            </p>
            <p>
              Por isso que aqui você terá oportunidade de tirar suas dúvidas ao
              vivo comigo, 100% de graça por dois ano, além do grupo de apoio
              que terá com outras alunas. Você entra lá e tira suas dúvidas
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuporteOnline;
