import "../style.scss";
import images from "../components/images";

function AntesDepois() {
  return (
    <div className="container-fluid background-gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>
              <b>É um curso que oferece uma visão clara e objetiva sobre </b>
              moda, estilo, autoconhecimento{" "}
              <b>
                e traz autonomia para construir seu guarda-roupa versátil cheio
                de estilo respeitando quem você é.
              </b>
            </h1>
            <p className="mt-4">
              Veja o Antes e Depois de algumas clientes felizes:{" "}
            </p>
          </div>
          <div className="col-sm-12 mt-4 mb-4">
            <div className="row box-wrap">
              <div className="col-sm-12 col-md-3 box">
                <img src={images.imgAntesDepois1} alt="" />
              </div>
              <div className="col-sm-12 col-md-3 box">
                <img src={images.imgAntesDepois2} alt="" />
              </div>
              <div className="col-sm-12 col-md-3 box">
                <img src={images.imgAntesDepois3} alt="" />
              </div>
              <div className="col-sm-12 col-md-3 box">
                <img src={images.imgAntesDepois4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AntesDepois;
