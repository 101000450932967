import axios from "axios";
import { useEffect, useState } from "react";
import { SERVICES_PHISICAL_URL } from "../services/config";
import ContextServicePhisical from "./ContextServicePhisical";

const ServicePhisicalProvider = ({ children }) => {
  const [coursesData, setCoursesData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(SERVICES_PHISICAL_URL).then(
        (response) => response.data
      );
      setCoursesData(response);
    };

    axiosCourses();
  }, []);

  return (
    <ContextServicePhisical.Provider value={coursesData}>
      {children}
    </ContextServicePhisical.Provider>
  );
};

export default ServicePhisicalProvider;
