import imgAulaOnline from "assets/landing/img/aula-online.png";
import imgAccess from "assets/landing/img/authorization.png";
import imgYear from "assets/landing/img/ano.png";
import imgWarrenty from "assets/landing/img/warrenty.png";
import imgPdf from "assets/landing/img/pdf.png";
import imgSafe from "assets/landing/img/safe.png";
import imgLiveClass from "assets/landing/img/live-class.png";
import imgLiveSupport from "assets/landing/img/live-support.png";
import imgStudentSupport from "assets/landing/img/student-support.png";
import imgOne from "assets/landing/img/one.png";
import imgTwo from "assets/landing/img/two.png";
import imgThree from "assets/landing/img/three.png";
import imgFour from "assets/landing/img/four.png";
import imgMetodoPiramideEstiloPessoal from "assets/landing/img/escalada-1.png";
import imgMetodoPiramideEstilo1 from "assets/landing/img/escalada-2.png";
import imgMetodoPiramideEstilo2 from "assets/landing/img/escalada-3.png";
import imgMetodoPiramideEstilo3 from "assets/landing/img/escalada-4.png";
import imgMetodoPiramideEstilo4 from "assets/landing/img/escalada-5.png";
import imgMetodoPiramideEstilo5 from "assets/landing/img/escalada-6.png";
import imgArrow from "assets/landing/img/arrow.png";
import imgWomanZeroCreative from "assets/landing/img/woman-zero-creative.jpg";
import imgWomanInsecure from "assets/landing/img/woman-insecure.jpg";
import imgWomanBasicClothes from "assets/landing/img/woman-basic-clothes.jpg";
import imgLuanaLopez from "assets/landing/img/luana-lopez.png";
import imgAntesDepois1 from "assets/landing/img/antes-depois-1.png";
import imgAntesDepois2 from "assets/landing/img/antes-depois-2.png";
import imgAntesDepois3 from "assets/landing/img/antes-depois-3.png";
import imgAntesDepois4 from "assets/landing/img/antes-depois-4.png";

const images = {
  online: imgAulaOnline,
  acessoAnual: imgYear,
  acessoImediato: imgAccess,
  garantia: imgWarrenty,
  suporteAlunos: imgStudentSupport,
  compraSegura: imgSafe,
  materialPdf: imgPdf,
  duvidasAoVivo: imgLiveClass,
  zoom: imgLiveSupport,
  one: imgOne,
  two: imgTwo,
  three: imgThree,
  four: imgFour,
  imgMetodoPiramideEstiloPessoal,
  imgMetodoPiramideEstilo1,
  imgMetodoPiramideEstilo2,
  imgMetodoPiramideEstilo3,
  imgMetodoPiramideEstilo4,
  imgMetodoPiramideEstilo5,
  imgArrow,
  imgLuanaLopez,
  imgWomanZeroCreative,
  imgWomanInsecure,
  imgWomanBasicClothes,
  imgAntesDepois1,
  imgAntesDepois2,
  imgAntesDepois3,
  imgAntesDepois4,
};

export default images;
