import "../style.scss";
import images from "../components/images";

function Lookinhos() {
  return (
    <div className="container-fluid background-blue">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="circle mx-auto">
              <h1>Aqui não é curso só de montar LOOKINHOS.</h1>
              <p className="mt-4">
                <b>É uma estratégia completa e de fácil entendimento.</b>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-4 mt-md-0">
            <ul className="list-numbers">
              <li>
                <img src={images.one} alt="" />
                <b>NÃO É CURSO DE MODA</b>, mas você vai conseguir ter acesso
                aos 'códigos da moda' e montar a solução que funciona para você
                com autonomia, autenticidade e respeitando quem você é.
              </li>
              <li>
                <img src={images.two} alt="" />
                <b>NÃO É CURSO DE AUTOAJUDA</b>, mas você vai aprender a
                entender sobre si mesma e como usar esse autoconhecimento para
                elevar sua autoestima e encontrar seu estilo através da pirâmide
                do estilo pessoal
              </li>
              <li>
                <img src={images.three} alt="" />
                <b>NÃO É CURSO DE MODELAGENS</b>, porém, irá aprender a dominar
                os elementos de design das roupas e acessórios.
              </li>
              <li>
                <img src={images.four} alt="" />
                <b>NÃO É CURSO DE FINANÇAS</b>, mas você vai aprender a fazer
                compras com estratégia, inteligência e consequentemente
                economizará 50% do que gasta hoje com roupas e acessórios.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lookinhos;
