import { orange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
// import Contato from "assets/img/top-5-reasons-why-you-need-a-social-media-manager-616015983b3ba-sej-1520x800-1.png";

const useStyles = makeStyles({
  root: {
    height: "700px",
    position: "relative",
    backgroundColor: orange[50],
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    "&::before": {
      width: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      content: '""',
      display: "block",
      //backgroundImage: `url(${Contato})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "100%",
      zIndex: "-999",
      opacity: "0.2",
    },
  },
  containerSection: {
    marginTop: theme.spacing(4),
  },
  image: {
    width: "300px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  form: {
    border: "3px solid black",
    padding: "32px 32px 64px 32px",
    "&": {
      "& div": {
        marginBottom: "16px",
      },
      "& button": {
        float: "left",
        backgroundColor: "#000",
      },
    },
  },
});

export default useStyles;
