export const COURSES_URL = "db/courses.json";
export const SERVICES_ONLINE_URL = "db/services_online.json";
export const SERVICES_PHISICAL_URL = "db/services_remote.json";
export const WP_URL = "db/whatsapp.json";
export const SECTIONS_COLORS_URL = "db/sections_colors.json";
export const CONTACT_URL = "db/contact.json";
export const ABOUT_URL = "db/about.json";
export const MAIN_BANNER_URL = "db/main_banner.json";
export const API_TIMELINE_ONLINE = "db/page_services_online.json";
export const API_TIMELINE_PRESENCIAL = "db/page_services_remote.json";

// export const API_URL = "https://lulopez.herokuapp.com/api";
// // export const API_URL = "http://localhost:1337/api";
// export const COURSES_URL = `${API_URL}/courses`;
// export const SERVICES_ONLINE_URL = `${API_URL}/services-onlines?populate=*`;
// export const SERVICES_PHISICAL_URL = `${API_URL}/servicesphisicals?populate=*`;
// export const WP_URL = `${API_URL}/whatsapp`;
// export const SECTIONS_COLORS_URL = `${API_URL}/sections-color`;
// export const CONTACT_URL = `${API_URL}/contact?populate=*`;
// export const ABOUT_URL = `${API_URL}/about?populate=*`;
// export const MAIN_BANNER_URL = `${API_URL}/main-banner?populate=*`;
// export const API_TIMELINE_ONLINE = `${API_URL}/page-servicos-online?populate=*`;
// export const API_TIMELINE_PRESENCIAL = `${API_URL}/page-servicos-presencial?populate=*`;
