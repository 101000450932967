import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ContextFirebase from "./ContextFirebaseAuth";

const FirebaseProvider = ({ children }) => {
  const [userLogin, setUserLogin] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user?.accessToken || undefined) {
        setUserLogin(user?.accessToken);
      } else {
        setUserLogin(false);
      }
    });
  }, []);

  return (
    <ContextFirebase.Provider value={userLogin}>
      {userLogin === null ? "carregando" : children}
    </ContextFirebase.Provider>
  );
};

export default FirebaseProvider;
