import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { WP_URL } from "../../services/config";
import useStyles from "./useStyles";

function FloatingButtonWP() {
  const classes = useStyles();
  const [data, setData] = useState();

  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(WP_URL).then((response) => response.data);
      setData(response.data);
    };

    axiosCourses();
  }, []);

  useEffect(() => {
    const locationPathname = location.pathname;
    if (!locationPathname) return;
    setLocationPath(locationPathname);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      {data?.attributes && (
        <a
          href={`https://wa.me/+55${data?.attributes?.phone}/?text=${
            data?.attributes?.text
          }${locationPath === "/curso" ? " - CURSO" : ""}`}
          className="float"
          target="_blank"
          rel="noreferrer"
        >
          <i className={`fa fa-whatsapp ${classes.icon}`}></i>
        </a>
      )}
    </div>
  );
}

export default FloatingButtonWP;
