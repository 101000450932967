import "./style.scss";
import CadeMeuLook from "./sections/cade-meu-look";
import SuporteOnline from "./sections/suporte-online";
import Lookinhos from "./sections/lookinhos";
import AntesDepois from "./sections/antes-depois";
import QuemSeDestina from "./sections/quem-se-destina";
import PiramideEstiloPessoal from "./sections/piramide-estilo-pessoal";
import ZeroCriativa from "./sections/acha-zero-criativa";
import AlemDissoVaiAprender from "./sections/alem-disso-vai-aprender";
import LuanaLopez from "./sections/luana-lopez";
import Faq from "./sections/faq";
import FloatingButtonWP from "../../components/waMe/floatingButtonWP";

function LandingPage() {
  return (
    <>
      {<CadeMeuLook />}
      {<ZeroCriativa />}
      {<Lookinhos />}
      {<AntesDepois />}
      {<PiramideEstiloPessoal />}
      {<AlemDissoVaiAprender />}
      {<SuporteOnline />}
      {/* {<QuemSeDestina />} */}
      {<LuanaLopez />}
      {<Faq />}
      {<FloatingButtonWP />}
    </>
  );
}

export default LandingPage;
