function ButtonInscrever() {
  function goTo() {
    window.open(
      "https://hotmart.com/pt-br/marketplace/produtos/cade-meu-look/L66514326B",
      "_blank"
    );
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mb-4 btn-lg"
        onClick={goTo}
      >
        Compre agora
      </button>
    </>
  );
}

export default ButtonInscrever;
