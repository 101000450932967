import "./index.scss";
import { Link } from "react-scroll";
import { BiMenu } from "react-icons/bi";
import { useContext, useEffect, useState } from "react";
import ContextCourses from "../../global/ContextCourses";
import ContextServiceSectionsColors from "../../global/ContextServiceSectionsColors";
import { createGlobalStyle } from "styled-components";
import { createBrowserHistory } from "history";

const GlobalStyle = createGlobalStyle`
  p {
    color: ${(props) =>
      props.paragraph ? `${props.paragraph} !important` : "#000"};
  }
  h1,h2,h3,h4{
    color: ${(props) => (props.titles ? `${props.titles} !important` : "#000")};
  }
  h5,h6{
    color: ${(props) =>
      props.subtitles ? `${props.subtitles} !important` : "#000"};
  }
`;

function Header() {
  const browserHistory = createBrowserHistory();

  function myFunction() {
    const x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  function test() {
    setTimeout(() => {
      browserHistory.push("/");
      myFunction();
    }, 1000);
  }

  const user = useContext(ContextCourses);
  const [courses, setCourses] = useState();
  const [displayCourses, setDisplayCourses] = useState(false);

  useEffect(() => {
    if (!user) return;
    setCourses(user.data);
  }, [user]);

  useEffect(() => {
    if (courses === undefined) return;
    if (courses.length === 0) return;
    setDisplayCourses(true);
  }, [courses]);

  const colors = useContext(ContextServiceSectionsColors);

  return (
    <div className="header">
      <GlobalStyle
        titles={colors?.data && colors.data.attributes.typographTitle}
        paragraph={colors?.data && colors.data.attributes.typographParagraph}
        subtitles={colors?.data && colors.data.attributes.tipographSubtitle}
      />

      <div className="container px-0">
        <div className="topnav" id="myTopnav">
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="link"
          >
            LuLopes
          </Link>

          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="link"
            onClick={() => test()}
          >
            Sobre
          </Link>

          {displayCourses && (
            <Link
              to="courses"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="link"
              onClick={() => test()}
            >
              Cursos
            </Link>
          )}

          <Link
            to="serviceOnline"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="link"
            onClick={() => test()}
          >
            Serviços Online
          </Link>
          <Link
            to="servicePhysical"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="link"
            onClick={() => test()}
          >
            Serviços Presencial
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="link"
            onClick={() => test()}
          >
            Contato
          </Link>

          <a href="/curso" target="_blank" className="link">
            Curso
          </a>

          <a
            href="#!"
            onClick={(ev) => {
              ev.preventDefault();
              myFunction();
              return false; // old browsers, may not be needed
            }}
            className="icon"
          >
            <BiMenu />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
