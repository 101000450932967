import { orange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";

const useStyles = makeStyles({
  // root: {
  //   backgroundColor: orange[50],
  // },
  containerSection: {
    marginTop: theme.spacing(4),
    "& p": {
      textAlign: "justify",
    },
  },
  image: {
    width: "250px",
    height: "auto",
  },
});

export default useStyles;
