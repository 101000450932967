import { createTheme } from "@mui/material/styles";
import { orange, yellow } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    primary: {
      main: orange[900],
    },
    secondary: {
      main: yellow[900],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          small: "small",
        },
      },
    },
  },
});

export default theme;
