import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContextFirebase from "../../global/ContextFirebaseAuth";

function useIsLogin() {
  const user = useContext(ContextFirebase);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === false) {
      navigate("/login");
    } else {
      navigate("/admin");
    }
  }, [navigate, user]);
}

export default useIsLogin;
