import { Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ABOUT_URL } from "../../services/config";
import useStyles from "./useStyles";
import ReactMarkdown from "react-markdown";
import ContextServiceSectionsColors from "../../global/ContextServiceSectionsColors";

function About() {
  const classes = useStyles();

  const [data, setData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(ABOUT_URL).then((response) => response.data);
      setData(response.data);
    };
    axiosCourses();
  }, []);

  const colors = useContext(ContextServiceSectionsColors);

  return (
    <div
      className={`container-fluid ${classes.root}`}
      style={{
        backgroundColor: colors?.data ? colors.data.attributes.about : "#fff",
      }}
    >
      <div className="container">
        {data?.attributes?.title && (
          <Typography variant="h4" color="primary">
            {data.attributes.title}
          </Typography>
        )}
        <div className={`row ${classes.containerSection}`}>
          <div className="col-xs-12 col-md-6">
            {data?.attributes?.content && (
              <ReactMarkdown>{data.attributes.content}</ReactMarkdown>
            )}
          </div>
          <div className="col-xs-12 col-md-6 justify-content-center">
            {data?.attributes?.image?.data?.attributes?.url && (
              <img
                src={data.attributes.image.data.attributes.url}
                alt=""
                className={classes.image}
              />
            )}
          </div>
        </div>
        <div className="mt-4">
          {data?.attributes?.quote && (
            <ReactMarkdown>{data.attributes.quote}</ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
