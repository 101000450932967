import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "40px",
    right: "40px",
    backgroundColor: "#25d366",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    fontSize: "30px",
    boxShadow: "2px 2px 3px #999",
    zIndex: "100",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    marginTop: "16px",
    color: "#fff",
  },
});

export default useStyles;
