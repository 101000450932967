import axios from "axios";
import useStyles from "./useStyles";
import { useCallback, useEffect, useState } from "react";
import { MAIN_BANNER_URL } from "../../services/config";
import parse from "html-react-parser";
import { isMobile } from "react-device-detect";

import { getDownloadURL, listAll, getStorage, ref } from "firebase/storage";

function Home() {
  const classes = useStyles();

  const [data, setData] = useState();
  const [image, setImage] = useState();

  const storage = getStorage();
  const listRef = ref(storage, "home-banner/");

  useEffect(() => {
    listAll(listRef)
      .then(async (res) => {
        const { items } = res;
        const urls = await Promise.all(
          items.map((item) => {
            async function image() {
              return await getDownloadURL(item);
            }
            return image().then((result) => {
              return {
                url: result,
                full: item.fullPath,
              };
            });
          })
        );
        setData(urls);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapImages = useCallback(() => {
    let objectImages = { mobile: "", webHi: "", webLow: "" };
    data.map((element) => {
      function search(term) {
        return element.url.includes(term);
      }
      if (search("low")) {
        objectImages.mobile = element.url;
      } else if (search("medium")) {
        objectImages.webLow = element.url;
      } else if (search("hi")) {
        objectImages.webHi = element.url;
      }
      return null;
    });
    return objectImages;
  }, [data]);

  useEffect(() => {
    if (!data) return;
    isMobile ? setImage(mapImages().mobile) : setImage(mapImages().mobile);
    if (!isMobile) {
      setTimeout(() => {
        setImage(mapImages().webHi);
      }, 2000);
    }
  }, [data, mapImages]);

  return (
    <div className={classes.root}>
      {data && <img src={image} alt="" className={classes.image} />}
      <div className="container">
        <div className={classes.containerCustom}>
          <div className={classes.title}>
            {data?.attributes?.title && parse(data.attributes.title)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
