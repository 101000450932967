import "../style.scss";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i className="fa-solid fa-chevron-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Faq() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container-fluid background-gray">
      <div className="container">
        <h1>FAQ</h1>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="mt-4"
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>
              Funciona para quem não entende nada de estilo e moda?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A verdade é que se você não entende nadinha de moda e está
              começando do zero pode ser que sua curva de aprendizado seja menor
              do que quem já tem alguma experiência, no entanto, você irá
              aprender do mesmo jeito.
            </Typography>
            <Typography>
              O que é curva de aprendizado? É o tempo que você leva para
              “dominar” algo. Depois que você aprende, aquilo deixa de ser um
              problema para executar e se torna um hábito.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Funciona para quem é não é nada criativa?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Quando pensamos em uma pessoa criativa, logo vem na cabeça aquela
              pessoa que tem uma capacidade infinita de criar coisas
              mirabolantes e fora da caixinha, não é mesmo?
            </Typography>
            <Typography>
              A criatividade não é um dom, mas uma habilidade do ser humano
              ligada à nossa capacidade de invenção, reinvenção e inovação.
              Assim como qualquer competência, é possível praticar a
              criatividade para despertá-la e desenvolvê-la, tornando-se uma
              pessoa mais criativa.
            </Typography>
            <Typography>
              Só se cria algo a partir de experiências anteriores, como diz o
              ditado:
            </Typography>
            <Typography>
              Para quem só conhece martelo, todo parafuso é prego.
            </Typography>
            <Typography>
              A sua capacidade criativa está limitada porque você está se
              vestindo no piloto automático, não busca referências e não pratica
              novas possibilidades.
            </Typography>
            <Typography>
              Te garanto que com essa simples mudança de hábito, determinação e
              curiosidade, seu lado criativo vai se revelar.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>
              Funciona para quem está no processo de emagrecimento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Uma das primeiras aulas do curso, fala da importância de você ter
              um proposito, um objetivo, pois sem isso, não chegamos a lugar
              nenhum, mesmo que você faça uma faculdade em Harvard, sem um
              objetivo só terá um diploma. A mesma coisa para sua vida – se está
              no processo de emagrecimento você tem um objetivo de peso a ser
              alcançado e um prazo – se não tem, repense!
            </Typography>
            <Typography>
              O conhecimento uma vez absorvido ficará para sempre registrado no
              seu HD humano, portanto, continue com seu processo e em paralelo
              faça o curso.
            </Typography>
            <Typography>
              Recomendo apenas que não invista muito em roupas nesse momento, já
              que as chances de perdê-las são grandes, ou monte um armário
              cápsula para passar por este período mais leve e feliz e depois
              apenas ajuste-as.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Funciona para quem tem loja de roupas?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Neste curso você terá uma visão geral sobre estilos, proporção
              corporal x modelagens, cores, estampas, contraste pessoal, linhas,
              formas, etc.
            </Typography>
            <Typography>
              Eu não tenho dúvidas que se você começar a aplicar este
              conhecimento nos seus atendimentos, na divulgação das suas peças,
              atrairá mais clientes, pois agregará valor nos seus produtos.
            </Typography>
            <Typography>
              Que mulher não gostaria de ser atendida por uma pessoa que olhasse
              para o corpo dela e indicasse exatamente aquela peça que vai
              valorizar a silhueta? Ou ensinar a fazer um mix de estampas
              clássicas?
            </Typography>
            <Typography>
              Não é só vender roupa, é vender experiência e gerar valor na vida
              daquela sua vida.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography>
              Funciona para quem não tem coloração Pessoal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Dentro da coloração, nos trabalhamos o contraste pessoal, que é um
              recurso 100% online, onde você descobre as melhores estampas,
              cabelo e maquiagem, respeitando seu contraste.
            </Typography>
            <Typography>
              Você não precisa ter uma cartela de cores para dominar o seu
              estilo pessoal, mas sem o estilo pessoal você é só uma cartela de
              cores.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography>Funciona para empreendedoras?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Quando falamos que a primeira impressão é a que fica, é porque
              nosso cérebro instintivamente traduz os símbolos e as cores da
              nossa imagem em segundos e os significados desses símbolos são
              universais.
            </Typography>
            <Typography>
              A primeira impressão está baseada em critérios sociais, culturais
              e econômicos. Existe uma expectativa sobre como a pessoa irá agir,
              falar e se comportar. Isso acontece em função do que você já
              vivenciou até aqui. Portanto, sempre iremos “julgar” o outro de
              forma inconsciente nos primeiros segundos e isso pode ser bom ou
              ruim.
            </Typography>
            <Typography>
              Se você tem sua própria empresa, te convido a repensar sobre como
              está divulgando sua imagem pessoal por ai – as vezes você está
              atraindo o público errado simplesmente porque não tem uma
              estratégia de imagem clara.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
            <Typography>
              Já fiz alguns cursos e nada funcionou pra mim, o “Cadê meu look?”
              vai mesmo funcionar?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Todo aprendizado é uma via de mão dupla, você precisa se dedicar,
              fazer as atividades e colocar em prática o que foi ensinado. Meu
              curso é não mágica, se nada funcionou até agora, sugiro rever seu
              processo de aprendizado antes de entrar no “Cadê meu look?”, pois
              aqui vai exigir dedicação.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Faq;
