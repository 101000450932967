import { makeStyles } from "@mui/styles";
import theme from "../../../theme";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#DEDDDB",
  },
  img: {
    width: "400px",
    height: "auto",
    borderRadius: "32px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
      marginTop: "32px",
      marginBottom: "32px",
      borderRadius: "16px",
    },
  },
  list: {},
});

export default useStyles;
