import useStyles from "./useStyles";

import { Instagram } from "react-bootstrap-icons";
import { Tiktok } from "react-bootstrap-icons";
import { Linkedin } from "react-bootstrap-icons";
import { Pinterest } from "react-bootstrap-icons";

function Social({ className }) {
  const classes = useStyles();

  return (
    <ul className={`${classes.root} ${className}`}>
      <li>
        <a
          href="https://www.instagram.com/luanaanjoslopes/"
          target="blank"
          title="Instagram Lulopes"
        >
          <Instagram />
        </a>
      </li>
      <li>
        <a
          href="https://www.tiktok.com/@luanaanjoslopes"
          target="blank"
          title="Tiktok Lulopes"
        >
          <Tiktok />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/luana-lopes-620a2b35/"
          target="blank"
          title="linkedin Lulopes"
        >
          <Linkedin />
        </a>
      </li>
      <li>
        <a
          href="https://br.pinterest.com/llopes0097"
          target="blank"
          title="Pinterest Lulopes"
        >
          <Pinterest />
        </a>
      </li>
    </ul>
  );
}

export default Social;
