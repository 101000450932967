// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKa3L9yqENOm4fmGBl_afopJip9Hh-eeM",
  authDomain: "lulopez.firebaseapp.com",
  projectId: "lulopez",
  storageBucket: "lulopez.appspot.com",
  messagingSenderId: "606463241424",
  appId: "1:606463241424:web:966856d08ba7c644d342d5",
  measurementId: "G-EELCS4L8JX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const storage = getStorage(app);

export default app;
