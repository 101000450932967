import "./App.scss";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import AllPages from "./pages/allpages";
import LandingPage from "./pages/landingPageCourse";
import Login from "./pages/admin/login";
import Admin from "./pages/admin/admin";
import FirebaseProvider from "./global/FirebaseProvider";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<AllPages />} />
        <Route path="/curso" element={<LandingPage />} />
        <Route
          path="/login"
          element={
            <FirebaseProvider>
              <Login />
            </FirebaseProvider>
          }
        />
        <Route
          path="/admin"
          element={
            <FirebaseProvider>
              <Admin />
            </FirebaseProvider>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

LogRocket.init("wkjm10/teste");
// after calling LogRocket.init()
setupLogRocketReact(LogRocket);

export default App;
