import "../style.scss";
import images from "../components/images";

function LuanaLopez() {
  return (
    <div className="container-fluid background-gray-2 luana-lopez">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <img
              src={images.imgLuanaLopez}
              alt="A quem se destina"
              className="img-fluid mx-auto mt-12 img-luana-lopez"
            />
            <h3 className="mt-4">Oi, eu sou a Luana Lopez!</h3>
          </div>
          <div className="col-sm-12 col-md-8">
            <p>
              Em 2016 eu tomei uma decisão na minha vida - Contratei uma
              consultoria de estilo no formato híbrido, na época R$ 1800 era
              caro para mim, mas coloquei como objetivo, deixei de comprar
              roupas por um tempo e decidi investir em conhecimento.
            </p>
            <p>
              Eu até tentei encontrar meu estilo assistindo vídeos no Youtube,
              mas com a avalanche de informações que recebia diariamente,
              acabava me perdendo e muitas vezes ficando cada vez mais confusa.
              Me frustrava sempre em ter um armário cheio e toda ocasião
              diferente comprar uma peça nova que acabava usando umas 3x no
              máximo. Estava cansada de usar só preto e branco. Após a
              consultoria e dedicação em buscar entender cada vez mais sobre meu
              estilo, a relação com as roupas se tornou leve e gostosa, cada
              elogio que recebia eu sabia que estava no caminho certo.
            </p>
            <p>
              Eu costumo falar que se tivéssemos esse tipo de informação na
              escola, a relação com o nosso corpo, mente, roupas e calçados
              seria totalmente diferente. Mas até bem pouco tempo, esse tipo de
              conteúdo era um privilégio da classe alta e foi justamente
              pensando no impacto positivo que tive na minha vida e pensando em
              como eu poderia transformar a vida de outras mulheres, eu decidi
              ingressar na profissão de Consultora e fornecer um serviço
              acessível, com comprometimento, seriedade e seguindo um pilar
              importantíssimo na minha vida que é a liberdade de você ser quem
              é.
            </p>
            <h6>Entender sobre você não é futilidade.</h6>
            <p>
              Eu fico imaginando como seria se não tivesse investido nessa
              direção, se não tivesse dado prioridade em aprender. Estaria
              naquela mesma situação, infeliz com meu armário, zero confiança
              para me vestir, com baixa autoestima e frustrada em ver pessoas ao
              meu redor confiantes, se sentindo lindas e eu no mesmo lugar com
              as mesmas roupas sem graça.
            </p>
            <p>
              Você é a única pessoa que pode mudar sua realidade! Então invista
              no seu conhecimento e comece a dizer ao mundo quem você é de
              verdade.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LuanaLopez;
