import { Typography } from "@mui/material";
import { Accordion } from "react-bootstrap";
import useStyles from "./useStyles";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

import { useContext, useEffect, useState } from "react";
import ServiceOnlineContext from "../../../global/ContextServicesOnline";
import ReactMarkdown from "react-markdown";
import parse from "html-react-parser";
import ContextServiceSectionsColors from "../../../global/ContextServiceSectionsColors";
import axios from "axios";
import { API_TIMELINE_ONLINE } from "../../../services/config";

function ServiceOnline() {
  const classes = useStyles();

  const user = useContext(ServiceOnlineContext);
  const [courses, setCourses] = useState();
  const [coursesOrdered, setCoursesOrdered] = useState();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!user) return;
    setCourses(user.data);
  }, [user]);

  useEffect(() => {
    if (courses === undefined) return;
    if (courses.length === 0) return;

    let ordered = courses.sort(function (a, b) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });

    setCoursesOrdered(ordered);

    setDisplay(true);
  }, [courses]);

  const colors = useContext(ContextServiceSectionsColors);

  const [timelineData, setTimelineData] = useState();
  const [timelineOnlineDataImg, setIimelineOnlineDataImg] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(API_TIMELINE_ONLINE).then(
        (response) => response.data
      );
      setTimelineData(response);
    };

    axiosCourses();
  }, []);

  useEffect(() => {
    if (!timelineData?.data) return;
    setIimelineOnlineDataImg(
      timelineData?.data?.attributes?.timelineImage?.data?.attributes?.url
    );
  }, [timelineData]);

  return (
    <div
      className={`container-fluid ${classes.root}`}
      style={{
        backgroundColor: colors?.data
          ? colors.data.attributes.servicesOnline
          : "#fff",
      }}
    >
      <div className="container">
        {/* <Typography variant="h4" color="primary">
          {timelineData?.data?.attributes.title}
        </Typography> */}

        {/* {display && (
          <Accordion
            defaultActiveKey="0"
            className="mt-5 mb-5"
            color="secondary"
          >
            {coursesOrdered.map((item) => (
              <Accordion.Item eventKey={item.id} key={item.id}>
                <Accordion.Header>
                  {item.attributes.icon && parse(item.attributes.icon)}
                  {item.attributes.title && item.attributes.title}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row mt-4">
                    <div className="col-12 col-sm-6">
                      <ReactMarkdown>{item.attributes.content}</ReactMarkdown>
                      <ReactMarkdown>
                        {item.attributes.priceContent}
                      </ReactMarkdown>
                    </div>
                    <div className="col-12 col-sm-6">
                      {item.attributes?.image?.data?.attributes?.url && (
                        <img
                          src={`${item?.attributes?.image?.data?.attributes?.formats?.large?.url}`}
                          alt=""
                          className={`${classes.img} mx-auto d-block`}
                        />
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )} */}

        {timelineData?.data && (
          <>
            <Typography variant="h4" color="primary">
              {timelineData.data.attributes.timelineTitle}
            </Typography>
            <Typography>
              {timelineData.data.attributes.timelineSubtitle}
            </Typography>
            <img
              src={timelineOnlineDataImg}
              alt=""
              className={`mt-4 img-fluid`}
            />

            <Typography variant="h6" className="mt-4">
              {timelineData.data.attributes.timelineHeader1}
            </Typography>
            <Typography className="mt-2">
              {timelineData.data.attributes.timelineHeader2}
            </Typography>

            {timelineData?.data?.attributes?.timeline_consultoria_online_steps
              ?.data && (
              <Timeline position="alternate">
                {timelineData?.data?.attributes?.timeline_consultoria_online_steps?.data.map(
                  (item, index) => (
                    <TimelineItem key={item.attributes.publishedAt}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                      >
                        {item.attributes.title}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                          color={index % 2 === 0 ? "grey" : "primary"}
                        >
                          {item.attributes.icon && parse(item.attributes.icon)}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="span">
                          {item.attributes.titleStepInfo}
                        </Typography>
                        <Typography>
                          {item.attributes.descriptionStep}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                )}
              </Timeline>
            )}
          </>
        )}

        <Typography className="mb-5">
          {timelineData?.data?.attributes &&
            timelineData.data.attributes.timelineFooterInfo}
        </Typography>
        <ReactMarkdown>
          {timelineData?.data?.attributes?.timelinePricing &&
            timelineData.data.attributes.timelinePricing}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default ServiceOnline;
