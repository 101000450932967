import axios from "axios";
import { useEffect, useState } from "react";
import { SERVICES_ONLINE_URL } from "../services/config";
import ContextServicesOnline from "./ContextServicesOnline";

const ServiceOnlineProvider = ({ children }) => {
  const [coursesData, setCoursesData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(SERVICES_ONLINE_URL).then(
        (response) => response.data
      );
      setCoursesData(response);
    };

    axiosCourses();
  }, []);

  return (
    <ContextServicesOnline.Provider value={coursesData}>
      {children}
    </ContextServicesOnline.Provider>
  );
};

export default ServiceOnlineProvider;
