import axios from "axios";
import { useEffect, useState } from "react";
import { COURSES_URL } from "../services/config";
import ContextCourses from "./ContextCourses";

const CourseProvider = ({ children }) => {
  const [coursesData, setCoursesData] = useState();

  useEffect(() => {
    const axiosCourses = async () => {
      const response = await axios(COURSES_URL).then(
        (response) => response.data
      );
      setCoursesData(response);
    };

    axiosCourses();
  }, []);

  return (
    <ContextCourses.Provider value={coursesData}>
      {children}
    </ContextCourses.Provider>
  );
};

export default CourseProvider;
