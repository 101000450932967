import { makeStyles } from "@mui/styles";
import theme from "../../theme";
// import Contato from "assets/img/top-5-reasons-why-you-need-a-social-media-manager-616015983b3ba-sej-1520x800-1.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: "0 100px",
    justifyContent: "space-evenly",
    "& li": {
      listStyle: "none",
      cursor: "pointer",
      "& a": {
        color: "#e2aa8b",
        "&:hover": {
          color: "#000",
        },
      },
    },
  },
});

export default useStyles;
