import parse from "html-react-parser";
import { Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { useContext, useEffect, useState } from "react";
import ContextCourses from "../../global/ContextCourses";

function Courses() {
  const user = useContext(ContextCourses);
  const [courses, setCourses] = useState();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!user) return;
    setCourses(user.data);
  }, [user]);

  useEffect(() => {
    if (courses === undefined) return;
    if (courses.length === 0) return;
    setDisplay(true);
  }, [courses]);

  return (
    <>
      {display && (
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <h2>Cursos</h2>
              <div className="cardContainer mt-4 mb-4 row flex-sm-row">
                {courses.map((item) => (
                  <div
                    className="customCard col-12 col-sm-3"
                    key={item.attributes.title}
                  >
                    <div className="face face1">
                      <div className="content">
                        <h3>{item.attributes.title}</h3>
                      </div>
                    </div>
                    <div className="face face2">
                      <div className="content">
                        <Typography>
                          {parse(item.attributes.content)}
                        </Typography>
                        <a
                          href={item.attributes.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Clique aqui!
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Courses;
