import { useEffect } from "react";

import { Typography } from "@mui/material";
import { useState } from "react";

import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
  listAll,
  deleteObject,
} from "firebase/storage";
import useIsLogin from "./isLogin";
import { Container } from "react-bootstrap";
import logout from "./logout";

function Admin() {
  const [imgUrl, setImgUrl] = useState(null);
  const [images, setImages] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);

  useIsLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];

    if (!file) return;

    const storageRef = ref(storage, `home-banner/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
        });
      }
    );
  };

  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage();

  // Create a reference under which you want to list
  const listRef = ref(storage, "images/");

  useEffect(() => {
    listAll(listRef)
      .then(async (res) => {
        const { items } = res;
        const urls = await Promise.all(
          items.map((item) => {
            async function image() {
              return await getDownloadURL(item);
            }
            return image().then((result) => {
              console.log(result);
              return {
                url: result,
                full: item.fullPath,
              };
            });
          })
        );

        // Array of download URLs of all files in that folder
        setImages(urls);
        console.log(urls);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteFile(urlRef) {
    const imageRef = ref(storage, urlRef);
    deleteObject(imageRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }

  return (
    <>
      <Typography onClick={logout}>Deslogar</Typography>
      <Container>
        <form onSubmit={handleSubmit} className="form">
          <input type="file" />
          <button type="submit">Upload</button>
        </form>
        {!imgUrl && (
          <div className="outerbar">
            <div className="innerbar" style={{ width: `${progresspercent}%` }}>
              {progresspercent}%
            </div>
          </div>
        )}
        {imgUrl && <img src={imgUrl} alt="uploaded file" height={200} />}

        <div>
          {images
            ? images.map((image) => {
                return (
                  <>
                    <img
                      src={image.url}
                      alt="uploaded file"
                      height={200}
                      key={image.full}
                    />
                    <p onClick={(e) => deleteFile(image.full)} key={image.full}>
                      Deletar foto
                    </p>
                  </>
                );
              })
            : "Carregando a lista de imagens"}
        </div>
      </Container>
    </>
  );
}

export default Admin;
