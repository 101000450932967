import "../style.scss";
import images from "../components/images";
import ButtonInscrever from "../components/button";

import { isMobile } from "react-device-detect";

function CadeMeuLook() {
  return (
    <>
      <div className="container cade-meu-look">
        <div className="row">
          <div className="col-sm-12 col-md-5 mx-auto">
            <h1>
              <b>CÁPSULA</b> DO ESTILO
            </h1>
            <h5>NÃO PERCA MAIS TEMPO E DINHEIRO COM ROUPAS QUE NÃO USA.</h5>
          </div>
        </div>
        <div className="row mt-4 d-flex flex-lg-column-reverse">
          <div className="col-sm-12 col-md mt-4">
            <iframe
              width={isMobile ? "100%" : "560px"}
              height="315"
              src="https://www.youtube.com/embed/NDZ5ioFbX18"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-auto">
            <p>
              Seu Guarda-Roupa está lotado e seu look sempre com cara de sem
              graça? Pesquisas afirmam que <b>mulheres</b> usam apenas 30% da
              capacidade do seu armário e que gastam em média 20 minutos só para
              escolher o que vai vestir. Se você gasta muito tempo e dinheiro
              com roupas que acaba não usando e se achando sempre sem graça,
              <b>ASSISTA</b>o vídeo abaixo e descubra como eu transformei a vida
              mulheres como você como base no método <b>Guarda-Roupa 3x.</b>
            </p>
            <p>
              VISTA-SE COM INTENÇÃO E DESCRUBRA COMO TER PEÇAS ESSENCIAIS, CRIAR
              LOOKS MODERNOS E PRÁTICOS PARA QUALQUER OCASIÃO.
            </p>
          </div>
        </div>
        {/* comprar */}
        <div className="row mt-4 mb-4">
          <div className="col-sm-12 col-md-6 mx-auto">
            <p className="text-center mb-4">
              <b>Clique no botão abaixo</b> e aprenda a tirar da MESMICE seus
              LOOKS BÁSICOS, porque ser básica também é ter estilo.
            </p>
            <ButtonInscrever />
          </div>
        </div>
      </div>
      <div className="container-fluid background-blue p-3">
        <div className="row list mx-auto mt-4">
          <div className="col-6 col-md-3">
            <img src={images.duvidasAoVivo} alt="" />
            <p>Encontro mensal</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.online} alt="" />
            <p>100% online</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.acessoAnual} alt="" />
            <p>1 ano de acesso</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.acessoImediato} alt="" />
            <p>Acesso imediato</p>
          </div>
        </div>
        <div className="row list mx-auto mt-4">
          <div className="col-6 col-md-3">
            <img src={images.garantia} alt="" />
            <p>Garantia de 15 dias</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.suporteAlunos} alt="" />
            <p>Suporte aos alunos</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.compraSegura} alt="" />
            <p>Compra segura</p>
          </div>
          <div className="col-6 col-md-3">
            <img src={images.materialPdf} alt="" />
            <p>Material didático em PDF</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CadeMeuLook;
