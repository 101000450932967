import { Element } from "react-scroll";
import Home from "./home";
import About from "./about";
import Courses from "./cursos";
import ServiceOnline from "./servicesModes/online";
import ServicePhysical from "./servicesModes/physical";
import Contact from "./contact";
import ServiceOnlineProvider from "../global/ServiceOnlineProvider";
import ServicePhisicalProvider from "../global/ServicePhisicalProvider";
import ServiceSectionsColors from "../global/ServiceSectionsColorsProvider";
import CourseProvider from "../global/CourseProvider";
import Header from "../components/header";
import FloatingButtonWP from "../components/waMe/floatingButtonWP";
import Footer from "../components/footer";

function AllPages() {
  return (
    <>
      <ServiceOnlineProvider>
        <ServicePhisicalProvider>
          <CourseProvider>
            <ServiceSectionsColors>
              <div className="App">
                <div className="wrapper">
                  <Header />
                  <Element name="home">
                    <Home />
                  </Element>
                  <Element name="about">
                    <About />
                  </Element>
                  <Element name="courses">
                    <Courses />
                  </Element>
                  <Element name="serviceOnline">
                    <ServiceOnline />
                  </Element>
                  <Element name="servicePhysical">
                    <ServicePhysical />
                  </Element>
                  <Element name="contact">
                    <Contact />
                  </Element>
                  {/* <div className="push"></div> */}
                </div>
                <FloatingButtonWP />
                <Footer />
              </div>
            </ServiceSectionsColors>
          </CourseProvider>
        </ServicePhisicalProvider>
      </ServiceOnlineProvider>
    </>
  );
}

export default AllPages;
